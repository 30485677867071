.faq-input:checked + div {
  @apply max-h-[500px];
  .label {
    @apply text-purple500;
    .arrow {
      @apply rotate-0;
    }
  }
}

.footer-acc-content {
  @apply max-h-[48px] pt-3 overflow-hidden transition-all duration-300 cursor-pointer;
}

.footer-acc-input:checked + .footer-acc-content {
  @apply max-h-[500px];
  .label {
    @apply text-purple500;
    .arrow {
      @apply rotate-0;
    }
  }
}

.accordion_content {
  @apply pr-6  overflow-x-auto;

  a {
    @apply text-blue600 underline visited:text-purple600 hover:text-blue800;
  }

  p {
    @apply pr-5 md:pr-6;
  }

  table,
  th,
  td {
    @apply border border-gray-300 border-collapse table-fixed;
  }

  table {
    @apply mt-2;

    th,
    td {
      @apply px-2 py-4;
    }
  }

  ol,
  ul {
    @apply pl-8;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }
}

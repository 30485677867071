.credit-card-table {
  & > div {
    @apply border-b border-b-black300;
  }
  @media (min-width: 1120px) {
    & > :nth-child(odd) {
      @apply border-r border-r-black300;
    }
    & > :nth-child(even) {
      @apply pl-8;
    }
    & > :nth-last-child(-n + 2) {
      @apply border-b-0;
    }
  }
}

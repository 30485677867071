.hero {
  mask-image: linear-gradient(to left, transparent, transparent 12px, black 47px, black);
  width: 100%; /* Adjust as necessary */
  height: auto; /* Adjust as necessary */
}
_::-webkit-full-page-media,
_:future,
:root .safari-only-hero {
  mask-image: linear-gradient(to left, transparent, transparent 1px, black 17px, black);
  margin-top: 21px;
}

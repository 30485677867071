@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  .modal-open {
    overflow: hidden;
  }
}
@import 'mixins';
@import 'short-class';
@import 'slider';
@import 'kkarti-hero';
@import 'kkarti-kamp-detay';
@import 'kkarti-kampanyalar';
@import 'header-desktop';
@import 'header-mobil';
@import 'accordion';
@import 'how-it-works';
@import 'credit-card-detail-table';
@import 'legel-text';
@import 'table-list-formatting';
@import 'richtext-reset';
@import 'pos-list-item';
@import 'badge'; // buraya veya aşağıya sadece dosya import edin!!!

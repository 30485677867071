.table-border {
  table,
  th,
  td {
    @apply border border-gray-300 border-collapse;
  }
}

.table-list-formatting {
  table,
  th,
  td {
    @apply table-fixed;
  }

  table {
    @apply mt-2 w-full table md:table;

    th,
    td {
      @apply px-2 py-4 whitespace-nowrap md:whitespace-normal;
    }
  }

  th {
    @apply w-32 first:w-20;
  }

  ul,
  ol {
    @apply pl-8;
  }

  ol {
    @apply list-decimal;
    @apply list-inside;
  }

  ul {
    @apply list-disc;
    @apply list-inside;
  }

  li {
    @apply my-2;
  }

  // .pos-tabs { // src/features/pos/detail/pos-tabs/pos-tabs.tsx for line 16 & line 18
  //   @apply list-disc;
  //   @apply list-inside;
  // }
}

.dob-hero-title {
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-lg font-bold leading-6 text-black900 md:text-[28px] md:leading-9;
  }
}

.dob-hero-desc-text {
  p {
    @apply text-sm font-semibold leading-[22px] text-black800 md:text-base md:leading-6;
    span {
      @apply text-sm font-bold leading-[22px] md:text-base md:leading-6;
    }
  }
}

.dob-campaign-detail {
  ul {
    @apply flex flex-col gap-3 px-4;
    li {
      @apply list-outside list-disc text-sm leading-6;
    }
  }
}

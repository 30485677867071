.mobileTop {
  .hmbrMenu {
    &.hmgrOpened {
      div {
        &:first-child {
          @apply rotate-45;
        }

        &:nth-child(2) {
          @apply opacity-0 translate-x-5 bg-transparent;
        }

        &:nth-child(3) {
          @apply rotate-[-45deg];
        }
      }
    }
  }
}

.mobileMenu {
  & > li {
    .accTitle {
      .arrow {
        .icon {
          @apply text-gray-500;
        }
      }

      &.disabled {
        @apply text-gray-500 pointer-events-none;
      }
    }

    .subMenu {
      ul {
        li {
          a {
            &.disabled {
              @apply text-gray-500 pointer-events-none;
            }
          }
        }
      }
    }

    &.activedItem {
      .accTitle {
        .arrow {
          @apply rotate-180;
          .icon {
          }
        }
      }

      .subMenu {
        @apply block;
      }
    }

    &.closedItem {
      .accTitle {
        .arrow {
          @apply rotate-0;
          .icon {
          }
        }
      }

      .subMenu {
        @apply hidden;
      }
    }
  }

  &.activedMenu {
    @apply left-0;
  }

  &.closedMenu {
    @apply left-[-100%];
  }
}

.navigation-wrap {
  .logo {
    svg {
      @apply w-full h-full;
    }
  }

  .navbar {
    ul {
      .navItem {
        .navSubItem {
          @apply hidden;
          .subItemList {
            li.inner {
              .content {
                &:hover {
                  .titleArea {
                    .title {
                    }
                  }

                }
              }

              &.subdisabled {
                @apply pointer-events-none;
                .content {
                  .titleArea {
                    .title {
                      @apply text-black600;
                    }
                  }

                  p {
                    @apply text-black600;
                  }
                }
              }
            }
          }
        }

        &:hover {
          .navSubItem {
            @apply flex;
          }

          .navLink {
            .arrow {
              @apply rotate-180;
            }
          }
        }

        &.disabled {
          @apply pointer-events-none;
          a {
            @apply text-[#848a97];
            // @apply text-black600;
          }
        }
      }
    }
  }
}

.deskMenuShade {
  &.active {
    @apply block;
  }
}

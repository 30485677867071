.kKartDetay {
  grid-template-areas:
    'logo'
    'campImg'
    'shortDescription'
    'startEndDate'
    'applyBtn';
  .logo {
    grid-area: logo;
  }
  .campImg {
    grid-area: campImg;
  }
  .shortDescription {
    grid-area: shortDescription;
  }

  .startEndDate {
    grid-area: startEndDate;
  }
  .campInfo {
    grid-area: campInfo;
  }
  .applyBtn {
    grid-area: applyBtn;
  }
}
.listDisc {
  ul {
    li {
      list-style-type: disc;
      line-height: 24px;
      font-size: 14px;
    }
  }
}
@media screen and (min-width: 1120px) {
  .kKartDetay {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'logo .'
      'campImg shortDescription'
      'campImg startEndDate'
      'campImg applyBtn';
  }
  .listDisc {
    ul {
      li {
        list-style-type: disc;
        line-height: 32px;
        font-size: 16px;
      }
    }
  }
}

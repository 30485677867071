.howItWorks {
  grid-template-areas: 'title ' 'desc' 'video ' 'button ';

  .howItWorksVideo {
    grid-area: video;
  }

  .howItWorksTitle {
    grid-area: title;
  }

  .howItWorksDesc {
    grid-area: desc;
  }

  .howItWorksBtn {
    grid-area: button;
  }
}

@media screen and (min-width: 1120px) {
  .howItWorks {
    grid-template-areas:
    'video .'
    'video title'
    'video desc'
    'video button'
    'video .';
    grid-template-columns: 1fr 1fr;
  }
}

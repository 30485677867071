h1 {
  @apply text-5xl font-bold md:text-2xl;
}

.h1-semi {
  @apply text-5xl font-semibold md:text-2xl;
}

.h1-regular {
  @apply text-5xl font-normal md:text-2xl;
}

h2 {
  @apply text-4xl font-bold md:text-xl;
}

.h2-semi {
  @apply text-4xl font-semibold md:text-xl;
}

h3 {
  @apply text-3xl font-bold md:text-lg;
}

.h3-semi {
  @apply text-3xl font-semibold md:text-lg;
}

h4 {
  @apply text-base;
}

h5 {
  @apply text-sm;
}

/*base box shadows*/
.boxShadow {
  box-shadow: 4px 4px 2px -4px rgba(0, 0, 0, 0.1);
}

.shadowv2 {
  box-shadow: 0px 1px 3px 0px #1018301A;
}

.cardShadow {
  box-shadow:
    0 1px 3px 1px rgba(60, 64, 67, 0.2),
    0 2px 8px 4px rgba(60, 64, 67, 0.1);
}

.rotated {
  transform: rotate(180deg);
}

.checkCircle {
  @apply rounded-full text-white flex items-center justify-center;
  background: linear-gradient(135deg, #80e06f 0%, #58b847 100%);
}

.modal-dialog {
  width: calc(100% - 12px);
  @apply md:w-full md:max-w-md;
}

@layer base {

  body,
  html {
    font-family:
      Open Sans,
      system-ui,
      sans-serif;
    /* Adjust font size */
    -webkit-text-size-adjust: 100%;
    /* Font variant */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    height: 100%;
  }

  body {
    @apply text-base bg-white md:text-sm h-full;

    &.-fixed {
      @apply fixed inset-0 w-full;
    }
  }

  [data-nextjs-scroll-focus-boundary] {
    display: contents;
  }

  .CommercialCCgradientBorder {
    background:
      radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 11px, transparent 11px) 0% 0%/12px 12px no-repeat,
      radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 11px, transparent 11px) 100% 0%/12px 12px no-repeat,
      radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 11px, transparent 11px) 0% 100%/12px 12px no-repeat,
      radial-gradient(circle at 0 0, #ffffff 0, #ffffff 11px, transparent 11px) 100% 100%/12px 12px no-repeat,
      linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 2px) calc(100% - 24px) no-repeat,
      linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 24px) calc(100% - 2px) no-repeat,
      linear-gradient(359deg, #eff0f6 0%, rgba(243, 201, 132, 0) 50%, #f6a927 100%);
  }

  .CommercialCClinkCircle {
    &::before {
      content: '';
      border-radius: 50%;
      margin-right: 0.5rem;
      background-color: rgba(242, 103, 34, 0.1);
      display: inline-block;
      height: 36px;
      width: 36px;
    }
  }

  @media (max-width: 575px) {
    .CommercialCClinkCircle {
      &::before {
        height: 28px;
        width: 28px;
      }
    }
  }

  .orange500Svg {
    path {
      @apply stroke-orange500;
    }
  }

  .purple500Svg {
    path {
      @apply stroke-purple500;
    }
  }

  .whiteSvg {
    path {
      @apply stroke-white;
    }
  }

  .black600Svg {
    path {
      @apply stroke-black600;
    }
  }
}

.qrCorneredBorder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-position:  0 0, 0 0, 101% 0, 0 101%;
    background-size: 3px 100%, 100% 3px, 3px 100% , 100% 3px;
    background-repeat: no-repeat;
    background-image:
        linear-gradient(0deg, #577DA6, #577DA6 25%, transparent 25%, transparent 75%, #577DA6 75%),
        linear-gradient(90deg, #577DA6, #577DA6 25%, transparent 25%, transparent 75%, #577DA6 75%),
        linear-gradient(180deg, #577DA6, #577DA6 25%, transparent 25%, transparent 75%, #577DA6 75%),
        linear-gradient(270deg, #577DA6, #577DA6 25%, transparent 25%, transparent 75%, #577DA6 75%)
    ;
    box-sizing: border-box;
    border-radius: 4px;

}

.gradientYellowBorderBg {
  background:
    radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 11px, transparent 11px) 0% 0%/12px 12px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 11px, transparent 11px) 100% 0%/12px 12px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 11px, transparent 11px) 0% 100%/12px 12px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 11px, transparent 11px) 100% 100%/12px 12px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 2px) calc(100% - 24px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 24px) calc(100% - 2px) no-repeat,
    linear-gradient(0deg, #eff0f6 10%, #d8dade 10%, #f6a927 100%);
  border-radius: 12px;
  box-sizing: border-box;
}

.gradientBlueBorderBg {
  background:
    radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 11px, transparent 11px) 0% 0%/12px 12px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 11px, transparent 11px) 100% 0%/12px 12px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 11px, transparent 11px) 0% 100%/12px 12px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 11px, transparent 11px) 100% 100%/12px 12px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 2px) calc(100% - 24px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 24px) calc(100% - 2px) no-repeat,
    linear-gradient(359deg, #eff0f6 0%, rgba(243, 201, 132, 0) 50%, #1877f2 100%);
}

.gradientPurpleBorderBg {
  background:
    radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 11px, transparent 11px) 0% 0%/12px 12px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 11px, transparent 11px) 100% 0%/12px 12px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 11px, transparent 11px) 0% 100%/12px 12px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 11px, transparent 11px) 100% 100%/12px 12px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 2px) calc(100% - 24px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 24px) calc(100% - 2px) no-repeat,
    linear-gradient(359deg, #eff0f6 0%, rgba(243, 201, 132, 0) 50%, #5d5bea 100%);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: rgb(54 57 64 / var(--tw-text-opacity)) !important; //text-black800
}

.tooltip-arrow-down {
  width: 0;
  height: 0;
  border-left: 7.5px solid transparent;
  border-right: 7.5px solid transparent;
  border-top: 7.5px solid white;
}

.openFilterModal {
  @apply fixed inset-0 w-full h-full bg-black200 z-50;
}

.tapHighlightColorTransparent {
  -webkit-tap-highlight-color: transparent;
}

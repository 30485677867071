.full-image-swiper-pagination {
  display: block;
  position: static;
  text-align: center;
  //transition: 0.3s opacity;
  //transform: translate3d(0, 0, 0);
  z-index: 30;
  bottom: -48px;
  left: 0;
  width: 100%;

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: #EFEFFD;
    margin: 0 8px;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background: #4A49BB;
    }
  }
}

.pos-list-item {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "logo"
    "numbers"
    "buttons"
    "campaigns";
  width: 100%;

  .logo {
    grid-area: logo;

  }

  .numbers {
    grid-area: numbers;
  }

  .campaigns {
    grid-area: campaigns;
    border-top: 1px dashed #E6E9EF;
  }

  .buttons {
    grid-area: buttons;
  }
}

@media screen and (min-width: 1120px) {
  .pos-list-item {
    grid-template-columns: 1fr  152px;
    grid-template-areas:
    "logo buttons"
    "numbers buttons"
    "campaigns campaigns";
  }
}